import React, { useContext } from 'react';
import styled from "styled-components";

import { TransactionContext } from "../../context/TransactionContext";
import { shortenAddress } from "../../utils/shortenAddress";

const Connect = () => {

    const { currentAccount, connectWallet, handleChange, sendTransaction, formData, isLoading } = useContext(TransactionContext);

    const handleSubmit = (e) => {
        const { addressTo, amount, keyword, message } = formData;

        e.preventDefault();

        if (!addressTo || !amount || !keyword || !message) return;

        sendTransaction();
    };

    return (
        <StyledDiv className="connect">
            {!currentAccount && (
                <div onClick={connectWallet} className={`inline-block text-2xl px-4 py-2 leading-none border rounded-full text-white hover:border-transparent hover:text-[#181670] hover:bg-white mt-4 lg:mt-0 cursor-pointer`}>
                    Connect Metamask
                </div>
            )}
            {currentAccount && (
                <div className={`inline-block text-2xl px-4 py-2 leading-none border rounded-full text-white mt-4 lg:mt-0 cursor-pointer`}>
                    {shortenAddress(currentAccount)}
                </div>

            )}

        </StyledDiv>
    )
}

const StyledDiv = styled.div`
    &.connect{
        
    }
`;

export default Connect