import { Navbar, Welcome } from "./components";

function App() {
  return (
    <>
      <Navbar />
      <Welcome />
    </>
  );
}

export default App;
