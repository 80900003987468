import React from 'react';
import styled from "styled-components";

import Build from '../../images/home/build.png';
import Conquer from '../../images/home/conquer.png';
import Earn from '../../images/home/earn.png';
import PlayLabel from '../../images/home/playtoearn.png';
import InnerLine from '../../images/home/inner_line.png';

const PlayToEarn = () => {
    return (
        <StyledDiv className="play-to-earn">
            <div className="flex w-full justify-center items-center text-white pb-20">
                <section className="container-lg mx-auto px-6 p-10">
                    <div className="flex items-center flex-wrap mb-20 text-center">
                        <div className="w-full flex justify-center">
                            <img src={PlayLabel} alt="Play To Earn" className="m-[-80px]" />
                        </div>
                    </div>
                    <div className="flex items-center flex-wrap mb-20 text-center">
                        <div className="w-full md:w-1/3 card-earn">
                            <div className="flex justify-center">
                                <img src={Build} alt="BUILD" className="h-60 w-60" />
                            </div>
                            <div className="mr-5 ml-5">
                                <h4 className="text-3xl text-white-800 font-bold mt-10 mb-10">BUILD</h4>
                                <p className="text-white-600 mb-8 text-xl">Build your own team with the rarest NFT ships, equipments and items from the universe.</p>
                            </div>
                        </div>


                        <div className="w-full md:w-1/3 card-earn">
                            <div className="flex justify-center">
                                <img src={Conquer} alt="CONQUER" className="h-60 w-60" />
                            </div>
                            <div className="mr-5 ml-5">
                                <h4 className="text-3xl text-white-800 font-bold mt-10 mb-10">CONQUER</h4>
                                <p className="text-white-600 mb-8 text-xl">Travel to the stars, discover new worlds and win the epic battles.</p>
                            </div>
                        </div>


                        <div className="w-full md:w-1/3 card-earn">
                            <div className="flex justify-center">
                                <img src={Earn} alt="EARN" className="h-60 w-60" />
                            </div>
                            <div className="mr-5 ml-5">
                                <h4 className="text-3xl text-white-800 font-bold mt-10 mb-10">EARN</h4>
                                <p className="text-white-600 mb-8 text-xl">Earn NFTs by playing the game, then sell it on the marketplace for profit.</p>
                            </div>
                        </div>


                    </div>

                </section>
            </div>

        </StyledDiv>
    )
}

const StyledDiv = styled.div`
    &.play-to-earn{
        .card-earn {
            padding: 12px 12px 96px;
            background-image: url(${InnerLine});
            mix-blend-mode: screen;
            background-position: bottom;
            background-repeat: no-repeat;
        }
    }
`;

export default PlayToEarn