import { useState } from "react";
import { HiMenuAlt4 } from 'react-icons/hi';
import { AiOutlineClose } from 'react-icons/ai';

import logo from '../images/logo.svg';
import Connect from "./metamask/Connect";

const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const menuText = [
        {
            "name": "NFT Marketplace",
            "link": "https://sip.space/market"
        },
        {
            "name": "Warzone",
            "link": "https://game.sip.space/"
        },
        {
            "name": "Roadmap",
            "link": "#roadmap"
        },
        {
            "name": "Whitepaper",
            "link": "https://docsend.com/view/gsftc6iyryh8na8v"
        },
        {
            "name": "Staking",
            "link": "https://sip.space/staking"
        },
        {
            "name": "My Collection",
            "link": "https://sip.space/my-collection"
        }
    ];

    const NavBarItem = ({ title, link, classprops }) => (
        <div onClick={(e) => {
            e.preventDefault();
            window.location.href = link;
        }} className={`block mt-4 lg:inline-block lg:mt-1 text-neutral-50 hover:text-white mr-10 text-xl cursor-pointer ${classprops}`}>{title}</div>
    );

    return (
        <nav className={`flex items-center justify-between flex-wrap bg-primary-700 p-6`}>
            <div className="flex items-center flex-shink-0 text-white mr-6">
                <img src={logo} alt="logo" className="w-32 cursor-pointer" />
            </div>
            <div className="block lg:hidden">
                <button className="flex items-right px-3 py-2 border rounded text-sky-200 border-sky-400 hover:text-white hover:border-white">
                    {!toggleMenu && (
                        <HiMenuAlt4 fontSize={28} className="text-white md:hidden cursor-pointer" onClick={() => setToggleMenu(true)} />
                    )}
                    {toggleMenu && (
                        <AiOutlineClose fontSize={28} className="text-white md:hidden cursor-pointer" onClick={() => setToggleMenu(false)} />
                    )}
                </button>
            </div>

            <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
                <div className="text-sm lg:flex-grow">
                    {menuText.map((item, index) => (
                        <NavBarItem key={item + index} title={item.name} link={item.link} />
                    ))}

                </div>
                <Connect />
            </div>
        </nav>
    )
}

export default Navbar;