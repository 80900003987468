import React from 'react';
import styled from "styled-components";
import PlayToEarn from './PlayToEarn';
import SpaceCardList from './SpaceCardList';

const Welcome = () => {
    return (
        <StyledDiv className={`welcome bg-primary-700 bg-[url('images/stars.png')] bg-repeat bg-top bg-center z-0`}>
            <section className="w-full h-screen bg-[url('images/welcome.jpg')] bg-cover bg-center py-4 px-6 stars">
                <div className="flex h-screen">
                    <div className="m-auto">
                        <button className={`bg-primary-700 hover:bg-primary-600 text-white font-bold py-6 px-6 rounded-full text-5xl`}>
                            Play Game
                        </button>
                    </div>
                </div>
            </section>
            <PlayToEarn />
            <SpaceCardList />

        </StyledDiv>
    )
}

const StyledDiv = styled.div`
    &.welcome{
        
    }
`;

export default Welcome;